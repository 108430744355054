var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card-header",attrs:{"color":"background","height":"100%"}},[_c('v-app-bar',{attrs:{"app":"","dark":"","color":"white","elevation":"0","clipped-left":""}},[_c('div',{staticClass:"d-none d-sm-flex d-md-flex d-lg-flex menu-button-container"},[_c('v-app-bar-nav-icon',{staticClass:"menu-button",attrs:{"color":"dark","id":"menuItem"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}})],1),_c('v-row',{staticClass:"d-sm-none d-sm-none d-md-none d-lg-none",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"1","align":"center","justify":"center"}},[_c('v-icon',{staticClass:"mr-3 ml-1",attrs:{"color":"dark"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_vm._v(" mdi-menu ")])],1),_c('v-col',{attrs:{"cols":"9"}},[(_vm.dataset.selectedOperation)?_c('v-menu',{attrs:{"min-width":"200","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mt-2 mr-4 d-sm-flex card-select-company",staticStyle:{"min-width":"200px !important"}},'div',attrs,false),on),[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('div',[_c('span',{staticClass:"d-sm-none d-sm-flex d-md-flex d-lg-none description-select-company",staticStyle:{"text-overflow":"ellipsis !important"}},[_vm._v(" "+_vm._s(_vm.truncateText(_vm.dataset.selectedOperation.nome, 18))+" ")]),_c('span',{staticClass:"d-none d-lg-flex description-select-company"},[_vm._v(" "+_vm._s(_vm.dataset.selectedOperation.nome)+" ")]),_c('span',{staticClass:"d-none d-lg-flex description-registration-select-company"},[_vm._v(" "+_vm._s(_vm.dataset.selectedOperation.cpfCnpj)+" ")]),_c('span',{staticClass:"d-sm-none d-md-none d-lg-none"},[_c('br')]),_c('span',{staticClass:"d-sm-none d-sm-flex d-md-flex d-lg-none description-registration-select-company"},[_vm._v(" "+_vm._s(_vm.dataset.selectedOperation.cpfCnpj)+" ")])])]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":"dark"}},[_vm._v(" mdi-arrow-down-drop-circle-outline ")])],1)],1)],1)]}}],null,false,1494460823)},[_c('v-list',[(_vm.dataset.operations.length > 1)?_vm._l((_vm.dataset.operations.filter(function (x) { return x.id != _vm.dataset.selectedOperation.id; })),function(operation,index){return _c('v-list-item',{key:index,attrs:{"link":"","color":"primary"},nativeOn:{"click":function($event){return _vm.changeOperation(operation)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(operation.nome))])],1)],1)}):[_c('v-list-item',[_c('v-list-item-content',[_c('span',{staticClass:"subtitle-2"},[_vm._v(" Nenhuma operação encontrada ")])])],1)]],2)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.user)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"mr-2"},'v-avatar',attrs,false),on),[_c('img',{attrs:{"src":'https://ui-avatars.com/api/?name=' + _vm.user.name}})])]}}],null,false,1577258120)},[_c('v-list',[_vm._l((_vm.profileMenu),function(menuOption,index){return [(!menuOption || !menuOption.children)?_c('MenuItem',{key:index,attrs:{"item":menuOption}}):_vm._e()]})],2)],1):_vm._e()],1)],1),[_c('div',{staticClass:"ml-16 d-none d-sm-flex"},[_c('v-img',{staticClass:"logo",attrs:{"id":"logo","src":require("@/assets/images/logo-lavepay.png"),"max-height":"80","max-width":"180","contain":""}})],1),(_vm.dataset.selectedOperation)?_c('v-menu',{staticClass:"d-none d-lg-flex",attrs:{"min-width":"200","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-none d-lg-flex ml-16 mt-2 mr-8 d-sm-flex card-select-company",staticStyle:{"min-width":"200px !important"}},'div',attrs,false),on),[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('div',[_c('span',{staticClass:"d-sm-none d-sm-flex d-md-flex d-lg-none description-select-company"},[_vm._v(" "+_vm._s(_vm.truncateText(_vm.dataset.selectedOperation.nome, 25))+" ")]),_c('span',{staticClass:"d-none d-lg-flex description-select-company"},[_vm._v(" "+_vm._s(_vm.dataset.selectedOperation.nome)+" ")]),_c('span',{staticClass:"d-none d-lg-flex description-registration-select-company"},[_vm._v(" "+_vm._s(_vm.dataset.selectedOperation.cpfCnpj)+" ")]),_c('span',{staticClass:"d-sm-none d-md-none d-lg-none"},[_c('br')]),_c('span',{staticClass:"d-sm-none d-sm-flex d-md-flex d-lg-none description-registration-select-company"},[_vm._v(" "+_vm._s(_vm.dataset.selectedOperation.cpfCnpj)+" ")])])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"color":"dark"}},[_vm._v(" mdi-arrow-down-drop-circle-outline ")])],1)],1)],1)]}}],null,false,673948829)},[_c('v-list',[(_vm.dataset.operations.length > 1)?_vm._l((_vm.dataset.operations.filter(function (x) { return x.id != _vm.dataset.selectedOperation.id; })),function(operation,index){return _c('v-list-item',{key:index,attrs:{"link":"","color":"primary"},nativeOn:{"click":function($event){return _vm.changeOperation(operation)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(operation.nome))])],1)],1)}):[_c('v-list-item',[_c('v-list-item-content',[_c('span',{staticClass:"subtitle-2"},[_vm._v(" Nenhuma operação encontrada ")])])],1)]],2)],1):_vm._e(),_c('v-spacer'),_c('v-spacer'),(_vm.user)?_c('v-menu',{staticClass:"d-none d-sm-flex",attrs:{"min-width":"240","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"logged-user d-none d-lg-flex ml-4"},'div',attrs,false),on),[_c('span',{staticClass:"logged-user-name font-weight-bold dark--text"},[_vm._v(_vm._s(_vm.user.name))]),_c('span',{staticClass:"logged-user-email mediumGray--text"},[_vm._v(_vm._s(_vm.user.email))])]),_c('v-avatar',_vm._g(_vm._b({staticClass:"d-none d-sm-flex"},'v-avatar',attrs,false),on),[_c('img',{attrs:{"src":'https://ui-avatars.com/api/?name=' + _vm.user.name}})])]}}],null,false,3956299113)},[_c('v-list',[_vm._l((_vm.profileMenu),function(menuOption,index){return [(!menuOption || !menuOption.children)?_c('MenuItem',{key:index,attrs:{"item":menuOption}}):_vm._e()]})],2)],1):_vm._e()]],2),_c('v-navigation-drawer',{attrs:{"app":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},_vm._l((_vm.sidebarMenu),function(menuGroup,index){return _c('v-list',{key:index,attrs:{"dense":"","subheader":""}},[((!menuGroup.path || _vm.$check(menuGroup.path, 'GET')) && menuGroup.children.length)?[_c('v-subheader',{staticClass:"menu-subheader"},[_vm._v(_vm._s(menuGroup.name))]),_vm._l((menuGroup.children),function(menuOption,index){return [(!menuGroup.path || _vm.$check(menuOption.path, 'GET'))?_c('MenuItem',{key:index,attrs:{"item":menuOption}}):_vm._e()]})]:_vm._e()],2)}),1),_c('v-divider',{staticClass:"mt-1"}),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }