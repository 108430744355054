const data = [
  {
    name: "Menu",
    path: null,
    children: [
      { name: "Visão Geral", icon: "mdi-home", route: "/home", path: null },
      { name: "Vendas", icon: "mdi-percent-outline", route: "/historico-vendas", path: null },
      { name: "Máquinas", icon: "mdi-washing-machine", route: "/maquinas", path: null },
      { name: "Upbox", icon: "mdi-chip", route: "/upboxes", path: null },
      { name: "Totens de Pagamento", icon: "mdi-credit-card-marker-outline", route: "/totens", path: null },
    ],
  },
];

export default [...data];
