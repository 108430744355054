<template>
    <v-card class="card-header" color="background" height="100%">
        <v-app-bar app dark color="white" elevation="0" clipped-left>
            <!-- <router-link class="title" to="/home">
            </router-link> -->

            <div class="d-none d-sm-flex d-md-flex d-lg-flex menu-button-container">
                <v-app-bar-nav-icon color="dark" class="menu-button" id="menuItem"
                    @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </div>

            <v-row class="d-sm-none d-sm-none d-md-none d-lg-none" justify="center" align="center">
                <v-col cols="1" align="center" justify="center">
                    <v-icon class="mr-3 ml-1" color="dark" @click.stop="drawer = !drawer">
                        mdi-menu
                    </v-icon>
                </v-col>
                <v-col cols="9">
                    <v-menu v-if="dataset.selectedOperation" min-width="200" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs" class="mt-2 mr-4 d-sm-flex card-select-company"
                                style="min-width: 200px !important;">
                                <v-row align="center" justify="center">
                                    <v-col cols="10">
                                        <div>
                                            <span style="text-overflow: ellipsis !important;"
                                                class="d-sm-none d-sm-flex d-md-flex d-lg-none description-select-company">
                                                {{ truncateText(dataset.selectedOperation.nome, 18) }}
                                            </span>
                                            <span class="d-none d-lg-flex description-select-company">
                                                {{ dataset.selectedOperation.nome }}
                                            </span>
                                            <span class="d-none d-lg-flex description-registration-select-company">
                                                {{ dataset.selectedOperation.cpfCnpj }}
                                            </span>
                                            <span class="d-sm-none d-md-none d-lg-none">
                                                <br>
                                            </span>
                                            <span
                                                class="d-sm-none d-sm-flex d-md-flex d-lg-none description-registration-select-company">
                                                {{ dataset.selectedOperation.cpfCnpj }}
                                            </span>
                                        </div>
                                    </v-col>
                                    <v-col cols="1">
                                        <v-icon color="dark">
                                            mdi-arrow-down-drop-circle-outline
                                        </v-icon>
                                    </v-col>
                                </v-row>
                            </div>
                        </template>
                        <v-list>
                            <template v-if="dataset.operations.length > 1">
                                <v-list-item
                                    v-for="(operation, index) in dataset.operations.filter((x) => x.id != dataset.selectedOperation.id)"
                                    :key="index" link color="primary" @click.native="changeOperation(operation)">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ operation.nome }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <template v-else>
                                <v-list-item>
                                    <v-list-item-content>
                                        <span class="subtitle-2"> Nenhuma operação encontrada </span>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </v-col>
                <v-col cols="2">
                    <v-menu v-if="user" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar class="mr-2" v-bind="attrs" v-on="on">
                                <img :src="'https://ui-avatars.com/api/?name=' + user.name" />
                            </v-avatar>
                        </template>
                        <v-list>
                            <template v-for="(menuOption, index) in profileMenu">
                                <MenuItem :key="index" :item="menuOption" v-if="!menuOption || !menuOption.children">
                                </MenuItem>
                            </template>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>

            <template class="d-none d-md-none d-lg-flex">

                <div class="ml-16 d-none d-sm-flex">
                    <v-img id="logo" class="logo" src="@/assets/images/logo-lavepay.png" max-height="80" max-width="180"
                        contain></v-img>
                </div>

                <v-menu class="d-none d-lg-flex" v-if="dataset.selectedOperation" min-width="200" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs"
                            class="d-none d-lg-flex ml-16 mt-2 mr-8 d-sm-flex card-select-company"
                            style="min-width: 200px !important;">
                            <v-row align="center" justify="center">
                                <v-col cols="10">
                                    <div>
                                        <span
                                            class="d-sm-none d-sm-flex d-md-flex d-lg-none description-select-company">
                                            {{ truncateText(dataset.selectedOperation.nome, 25) }}
                                        </span>
                                        <span class="d-none d-lg-flex description-select-company">
                                            {{ dataset.selectedOperation.nome }}
                                        </span>
                                        <span class="d-none d-lg-flex description-registration-select-company">
                                            {{ dataset.selectedOperation.cpfCnpj }}
                                        </span>
                                        <span class="d-sm-none d-md-none d-lg-none">
                                            <br>
                                        </span>
                                        <span
                                            class="d-sm-none d-sm-flex d-md-flex d-lg-none description-registration-select-company">
                                            {{ dataset.selectedOperation.cpfCnpj }}
                                        </span>
                                    </div>
                                </v-col>
                                <v-col cols="2">
                                    <v-icon color="dark">
                                        mdi-arrow-down-drop-circle-outline
                                    </v-icon>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                    <v-list>
                        <template v-if="dataset.operations.length > 1">
                            <v-list-item
                                v-for="(operation, index) in dataset.operations.filter((x) => x.id != dataset.selectedOperation.id)"
                                :key="index" link color="primary" @click.native="changeOperation(operation)">
                                <v-list-item-content>
                                    <v-list-item-title>{{ operation.nome }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-else>
                            <v-list-item>
                                <v-list-item-content>
                                    <span class="subtitle-2"> Nenhuma operação encontrada </span>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-menu>

                <v-spacer></v-spacer>

                <v-spacer></v-spacer>

                <v-menu class="d-none d-sm-flex" v-if="user" min-width="240" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <div class="logged-user d-none d-lg-flex ml-4" v-bind="attrs" v-on="on">
                            <span class="logged-user-name font-weight-bold dark--text">{{ user.name }}</span>
                            <span class="logged-user-email mediumGray--text">{{ user.email }}</span>
                        </div>

                        <v-avatar class="d-none d-sm-flex" v-bind="attrs" v-on="on">
                            <img :src="'https://ui-avatars.com/api/?name=' + user.name" />
                        </v-avatar>
                    </template>
                    <v-list>
                        <template v-for="(menuOption, index) in profileMenu">
                            <MenuItem :key="index" :item="menuOption" v-if="!menuOption || !menuOption.children">
                            </MenuItem>
                        </template>
                    </v-list>
                </v-menu>
            </template>
        </v-app-bar>
        <!-- <ProgressBar></ProgressBar> -->

        <v-navigation-drawer app v-model="drawer" temporary>
            <v-list dense subheader v-for="(menuGroup, index) in sidebarMenu" :key="index">
                <template v-if="(!menuGroup.path || $check(menuGroup.path, 'GET')) && menuGroup.children.length">
                    <v-subheader class="menu-subheader">{{ menuGroup.name }}</v-subheader>
                    <template v-for="(menuOption, index) in menuGroup.children">
                        <MenuItem v-if="!menuGroup.path || $check(menuOption.path, 'GET')" :item="menuOption"
                            :key="index">
                        </MenuItem>
                    </template>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-divider class="mt-1"></v-divider>

        <router-view></router-view>
    </v-card>
</template>

<script>
import { eventHub } from "@/main";

import MenuItem from "@/layouts/components/MenuItem";
import profileMenu from "@/common/data/profile-menu.data"
import sidebarMenu from "@/common/data/sidebar-menu.data"

var parse = require('json-parse-safe')

import axios from "axios"

export default {
    name: "PrivateLayout",

    components: {
        MenuItem
    },

    data() {
        return {
            logoUrl: null,
            menuGroups: [],
            drawer: false,
            searchTerm: null,
            user: null,
            displaySettingsMenu: false,
            sidebarMenu: sidebarMenu,
            profileMenu: profileMenu,
            dataset: {
                operations: [],
                selectedOperation: null
            }
        };
    },

    computed: {
        routeName() {
            return this.$route.name;
        },
    },

    mounted() {
        this.user = parse(localStorage.getItem('user')).value;

        eventHub.$on("logout", () => {
            this.logout();
        })

        this.setActionButtons();

        this.loadData()
    },

    destroyed() {
        eventHub.$off("THEME_UPDATED");
    },

    methods: {
        setActionButtons() {
        },

        menuActionClick(event) {
            event.preventDefault()
        },

        async logout() {
            const logoutUrl = "https://login.lavepay.com/realms/lavepay-bo/protocol/openid-connect/logout?post_logout_redirect_uri=https://portal.lavepay.com&client_id=web_app"

            window.open(logoutUrl, '_self');
        },

        async loadData() {
            await this.loadOperations();
        },

        truncateText(text, maxLength) {
            return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
        },

        async loadOperations() {
            this.dataset.loading = true;

            try {
                const result = await axios.get('/api/operacao');

                this.dataset.operations = result.data;

                if (this.dataset.operations.length) {
                    this.dataset.selectedOperation = this.dataset.operations[0];
                    localStorage.setItem('operation', this.dataset.selectedOperation.id)

                    eventHub.$emit('CHANGE-OPERATION', this.dataset.selectedOperation)
                }

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.loading = false;
            }

        },

        changeOperation(operation) {
            localStorage.setItem('operation', operation.id);

            this.dataset.selectedOperation = operation;

            console.log('change operation ', operation)

            eventHub.$emit('CHANGE-OPERATION', operation)
        }
    },
};
</script>

<style scoped lang="scss">
.title {
    color: var(--v-primary-base);
    text-decoration: none;
}

.card-header {
    border-radius: 0px;
}

.menu-button-container {
    background-color: var(--v-secondary-base) !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 80px;
    text-align: center;
}

.menu-button {
    margin: auto;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.logged-user {
    text-align: left;
    margin-right: 10px;
    font-size: 13px;
    font-weight: 400;
    flex-direction: column;

    &-name {
        display: block;
    }

    &-email {
        display: block;
    }
}

.menu-subheader {
    padding-left: 16px;
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.001px;
}

.menu-title {
    padding-left: 16px;
    color: var(--v-important-base);
    // font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}

.description-select-company {
    color: var(--v-dark-base);
    font-weight: bold;
}

.description-registration-select-company {
    color: var(--v-dark-base);
    font-size: 12px;
}
</style>